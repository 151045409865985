$black: #000;
$white: #fff;

$brand-secondary: #0568ae;
$brand-secondary-dark: #0069c0;
$errorText: #d50000;

$light-grey: #ddd;
$primary-grey: #565656;
$green: #388e3c;
$light-green: #4caf50;
$dark-green: #2e7d32;
$link-hover: #bababa;
$purple: #13173a;

$button-primary: #0568ae;
$button-primary-hover: #0473c2;
$button-primary-font: $light-grey;
$button-secondary: $light-green;

$menu-background: $white;

@font-face {
  font-family: "ATTAleckSans";
  src: url("/fonts/ATTAleckSans_Rg.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "ATTAleckSans";
  src: url("/fonts/ATTAleckSans_Th.ttf");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "ATTAleckSans";
  src: url("/fonts/ATTAleckSans_Bd.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.form-row {
  width: 100%;
  margin-top: 25px;
}

.form-button {
  width: 175px;
}

// hoa page overrides /subscription/payment
.field-group {
  margin-bottom: 12px;
}

.field-group__input {
  margin-bottom: 12px;
}

.ui.button {
  color: $button-primary-font;
  background-color: $button-primary;
  &:hover {
    color: $button-primary-font;
    background-color: $button-primary-hover;
  }
}

.ui.Segment {
  border-color: $button-primary;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.7);
}

.ui.secondary.inverted.menu {
  background-color: $menu-background;
}

.ui.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.homepage {
  min-height: 400rem;
}

.homepage-callback {
  margin-top: 0 !important;
  height: 100%;
}

// .nav-link:hover {
//   color: black !important;
// }

// .nav-link--active {
//   font-weight: bold;
//   color: black !important;
// }

.subscription-grid {
  background-color: #eaeaea !important;
}

.column.compact {
  margin-bottom: 0rem !important;
}

.subscription-grid--row {
  background-color: #d7d6d7 !important;
}

.ui[class*="condensed"].celled.grid > .row > .column {
  padding: 0.5em !important;
}

.ui[class*="condensed"].grid > .row {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}

.element--padding-top-10 {
  padding-top: 1em !important;
}

.element--background-color-slate-dark {
  background-color: #505457 !important;
}

.element--font-size-18 {
  font-size: 1.125rem;
}

.element--full-height {
  min-height: 100vh;
}

.ui.aligned.grid .column > .segment:not(.compact):not(.attached) {
  -webkit-box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.75) !important;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.75) !important;
}

.ui.aligned.grid .column > .active.segment:not(.compact):not(.attached) {
  -webkit-box-shadow: 0 0 32px 0 rgba(25, 171, 122, 1) !important;
  -moz-box-shadow: 0 0 32px 0 rgba(25, 171, 122, 1) !important;
  box-shadow: 0 0 32px 0 rgba(25, 171, 122, 1) !important;
}

.ui.card,
.ui[class*="element--background-color-slate-light"].circular.button,
.ui[class*="element--background-color-grey"].circular.button {
  -webkit-box-shadow: 2px 2px 5px 0 rgba(90, 108, 122, 1) !important;
  -moz-box-shadow: 2px 2px 5px 0 rgba(90, 108, 122, 1) !important;
  box-shadow: 2px 2px 5px 0 rgba(90, 108, 122, 1) !important;
}

.element--background-color-slate-light {
  background-color: #505457 !important;
  color: #eaeaea !important;
}

.element--background-color-grey {
  background-color: #c0c8cf;
}
.ui-button--hidden {
  display: none !important;
}

.ui-button--inline-block {
  display: inline-block !important;
}

.field.hidden {
  display: none !important;
}

.field {
  margin-top: 1em !important;
}

.ui.button.basic.link,
.ui.button.basic.link:hover,
.ui.button.basic.link:focus {
  box-shadow: 0 0 !important;
}

.ui.label {
  background-color: $white !important;
  padding-left: 0;
  color: $black !important;
}
